#question5 {
	h3{
		font-size: 34px;
		padding-bottom: 20px;
		color: orangered;
	}
	h2{
		max-width: 900px;
		line-height: 1.2;
		@media screen and (max-width: 600px) {
			line-height: 1;
		}
	}
	.answers {
		// background-color: red;
		display: block;
		padding: 0;
		ul {
			display: flex;
			justify-content: center;
			align-items: center;
			column-gap: 80px;
			font-size: 50px;
			@media screen and (max-width: 600px) {
				flex-direction: column;
			}
			
			label{
				display: block;
				line-height: 0.9;
			}
		}
		button {
			background-color: orangered;
			color: white;
			margin: 0 auto;
			margin-top: 20px;
			&:hover {
				color: white;
				background-color: blue !important;
				filter: none;
			}
		}
	}
}
