body {
	overflow-x: hidden;
}

.page_container {
	overflow: hidden;
	position: relative;
}
@import "../styles/clouds.scss";

@media (width <600px) {
	.clouds_1,
	.clouds_2 {
		display: none;
	}
}
.islands_container {
	// SWIPER
	swiper-container {
		position: static;
	}
	// SLIDES
	.swiper-slide {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
		// width: calc(75vw - 1px);
		width: fit-content;
		max-width: 900px;
		// background-color: red;
		padding-bottom: 20px;
		margin: 0 14vw;
		// background-color: darksalmon;

		&:has(.small_island) {
			width: 20vw;
			max-width: 700px;
		}
		@media (width < 900px) {
			width: 90%;
			&:has(.small_island) {
				width: fit-content;
				max-width: 100%;
			}
		}
	}

	.Island,
	.small_island {
		cursor: pointer;
		display: flex;
		// background-color: green;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		&:hover {
			// background-color: red;
			.text {
				h2.island_title {
					transform: skew(-9deg) scale(1.1); 
					color: rgb(255, 255, 255);
					// letter-spacing: 12px;
				}
				.breakDown {
					letter-spacing: 5px;
				}
			}

			.video_container {
				scale: 1.1;
			}
		}
	}
	.video_container {
		display: block;
		max-height: 600px;
		height: 60vh;
		width: 100%;
		@media (width < 900px) {
			height: 40dvh;
		}

		display: flex;
		justify-content: center;
		animation: floating_islands 1.8s ease-out infinite alternate;
		// transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) all;
		transition: 0.4s ease-out;
		// background-color: greenyellow;

		&:after {
			content: "";
			width: 100%;
			height: 100%;
			transform: translate(-50%, -50%);
			display: block;
			background: radial-gradient(rgb(200, 252, 251), transparent 60%);
			// background-color: red;
			position: absolute;
			top: 50%;
			z-index: -1;
			left: 50%;
			// mix-blend-mode: soft-light;
			opacity: 0.2;
		}
		video,
		img {
			// max-width: 100%;
			max-height: 100%;
			margin-top: auto;
			filter: saturate(0.95);
			@media (width < 1000px) {
				max-height: unset;
				width: 100%;
				height: 100%;
				// background-color: red;
			}
		}
	}
	.small_island {
		.video_container {
			height: fit-content;
			padding-bottom: 20px;
		}
		.text {
			transform: rotate(-4deg) skew(-4deg);

			h2.island_title {
				font-size: 54px;
				letter-spacing: 8px;
				// color: #ffdfa2;
			}
			p {
				max-width: 250px;
			}
		}
	}
	.text {
		// background-color: red;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	// CONTENT
	h2.island_title {
		text-align: center;
		color: white;
		font-family: crunold, sans-serif;
		font-family: crunold, sans;
		text-transform: uppercase;
		text-rendering: optimizeLegibility;
		font-size: 78px;
		font-size: clamp(2rem, 8vw, 78px);
		letter-spacing: 8px;
		margin: 0;
		// margin-top: -20px;
		font-weight: 100;
		line-height: 1;
		transition: 0.4s ease-out;
		text-align: center;
	}
	span.breakDown {
		font-family: "myoliver";
		font-family: "crunold", "din", sans-serif;
		font-size: 30px;
		letter-spacing: 4px;
		display: block;
		font-weight: 100;
		font-weight: 300;
		line-height: 0.8;
		letter-spacing: 4px;
		transition: 0.4s ease-out;
	}

	p {
		color: white;
		padding-top: 10px;
		line-height: 1.4;
		font-family: "din", sans-serif;
		font-size: 18px;
		font-weight: 200;
		max-width: 450px;
		text-align: center;
		// font-family: "myoliver", sans-serif;
		// font-size: 24px;
		// font-weight: 100;
		// text-transform: uppercase;
	}

	// .swiper-button-next {
	// 	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important;
	// }
}
.swiper-pagination {
	bottom: 20px !important;
	@media (height < 600px) {
		bottom: 15dvh !important;
	}
}

.swiper-pagination-bullet {
	background-color: white !important;
	opacity: 0.8;
	transform: scale(2.8);
	width: 14px;
	height: 14px;
}
.swiper-pagination-bullet-active {
	background-color: orangered !important;
}
.swiper-cta-prev,
.swiper-cta-next {
	z-index: 100;
	position: fixed;
	top: 50%;
	border: none;
	height: 80px;
	width: auto;
	background-color: orangered;
	background-color: transparent;
	aspect-ratio: 1/1;
	border-radius: 50%;
	transition: all 0.2s ease-out;
	&.disabled {
		opacity: 0.2;
	}
	&:hover {
		transform: scale(120%);
		border: 1px solid white;
	}
	img {
		width: 20px;
	}
	@media (width < 900px) {
		display: flex;
		justify-content: center;
		align-items: center;
		top: 90vh;
		background-color: orangered;
		height: 50px;
		img {
			max-width: 20%;
		}
	}
	@media (height < 600px) {
		top: unset;
		bottom: 50dvh;
		height: 40px;
	}
}
.swiper-cta-prev {
	left: 20vw;
	@media (width < 900px) {
		left: 20px;
	}
	@media (width < 900px) {
		left: 10px;
	}
}
.swiper-cta-next {
	right: 20vw;
	rotate: 180deg;
	@media (width < 900px) {
		right: 20px;
	}
	@media (width < 900px) {
		right: 10px;
	}
}

/* ########### CLOUDS  ########### */
@keyframes cloud_homeisland_apparition_1 {
	from {
		opacity: 0;
		// -webkit-transform: scaleX(-1) translate(100%, 20%);
		transform: scaleX(-1) translate(100%, 20%);
	}
	to {
		opacity: 1;
		// -webkit-transform: scaleX(-1) translate(50%, 20%);
		transform: scaleX(-1) translate(50%, 20%);
	}
}
@keyframes cloud_homeisland_apparition_2 {
	0% {
		opacity: 0;
		// -webkit-transform: scaleY(-1) translate(100%, 20%);
		transform: scaleY(-1) translate(100%, 50%);
	}
	100% {
		opacity: 1;
		// -webkit-transform: scaleY(-1) translate(50%, 50%);
		transform: scaleY(-1) translate(50%, 50%);
	}
}
