#question7 {
	h2.la_question {
		max-width: 900px;
	}
	.answers {
		// display: block;
		align-items: flex-start;
		align-items: center;
		padding-top: 30px;

		video {
			max-width: 250px;
		}

		.btns {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 80px;
			row-gap: 20px;
			@media screen and (max-width: 600px) {
				grid-template-columns: 1fr;
			}
			button {
				span {
					font-size: 60px;
					font-family: "myoliver";
					filter: none;
					border-radius: 50%;
					transform: translateX(-20px);
					width: auto;
					height: fit-content;
					aspect-ratio: 1/1;
					margin-bottom: -6px;
				}
				ul {
					font-size: 24px;
					padding: 0px;
				}
				&:hover {
					span {
						border: 1px solid orange;
					}
				}

				@media screen and (max-width: 600px) {
					span {
						margin: 0 auto;
					}
					span,
					ul {
						text-align: center;
					}
				}
			}
		}
	}
}
