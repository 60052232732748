.modal__wrapper--affiche {
	.modal__content__close:before,
	.modal__content__close:after {
		background-color: orangered !important;
		opacity: 100% !important;
	}
}

#modalaffiche {
	padding: 10px !important;
}
.affiche_foxes {
	max-height: 90dvh;
	cursor: grab;
}
