@import "../styles/generics/ctas.scss";
.intro_section {
	@import "../styles/clouds.scss";
	position: relative;
	overflow: hidden;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	.backrenards {
		position: absolute;
		left: 20px;
		top: 20px;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		.back_icon {
			transition: 0.3s ease-out;
			margin-right: 10px;
			height: 30px;
		}
		&:hover {
			color: orange;
			.back_icon {
				transform: scale(1.2);
				filter: brightness(0.65) sepia(3) saturate(20);
			}
		}
	}

	.container {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		height: 100%;
		opacity: 0.999;
		.dirigeable {
			width: auto;
			max-height: 300px;
			animation: floating_dirigeable 2s ease-out 2.4s infinite alternate,
				dirigeable_apparition 1.6s cubic-bezier(0, 0.73, 0.63, 1) 0.8s;
			animation-fill-mode: backwards;
			animation-delay: 0 4s;
		}
		.font_bonus {
			max-height: 200px;
			width: auto;
			animation: font_bonus_apparition 1.5s cubic-bezier(0, 0.13, 0.63, 1) 0.95s;
			animation-fill-mode: backwards;
		}
		@media (width < 800px) {
			.dirigeable,
			.font_bonus {
				max-width: 100%;
				height: auto;
			}
			max-width: 90vw;
			margin: 0 auto;
		}
	}

	.clickExplosion {
		position: fixed;
		mix-blend-mode: screen;
		transform: translate(-50%, -50%);
		position: absolute;
		z-index: 5;
		pointer-events: none;
		width: 300px;
		// height: 100px;
	}
}
