#modaljeunepublic {
	text-align: center;
	width: 90dvw;
	max-width: 1000px;
	padding: 80px 20px;

	h4 {
		font-family: "myoliver";
		font-size: 60px;
		text-rendering: geometricPrecision;
		font-weight: 100;
		margin: 0;
		margin-bottom: 20px;
		margin: 0 auto;
		max-width: 700px;
		text-align: center;
		line-height: 1;
	}
	.modal__description {
		padding: 30px 0;
		max-width: 650px;
		margin: 0 auto;
		line-height: 1.5;
	}
	.arrow {
		filter: invert(1);
		height:60px;
		transform: rotate(-120deg) scale(-1, 1);
		margin: 0 auto;
		margin-bottom: 20px;
	}
}
