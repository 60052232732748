#reponse3 {
	.reponse__detail {
		max-width: 700px;
	}
	h4 {
		font-size: 60px;
		// font-size: clamp(50px, 6vw, 60px);
		line-height: 0.9;
		padding-bottom: 20px;
	}
	h6 {
		max-width: 600px;
		margin: 0 auto;
	}
	p {
		max-width: 660px;
	}
	video {
		margin: 20px auto;
		max-height: 200px;
		filter: invert(100%) grayscale(100%) brightness(800%);
	}
}
