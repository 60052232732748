.quizz_container {
	position: relative;
	background-color: white;
	transition: all 1s linear;
	color: blue;
	width: 90%;
	max-width: 1200px;
	margin-top: 100px;
	margin-bottom: 100px;

	input[type="checkbox"] {
		flex-shrink: 0;
		appearance: none;
		background-color: blue;
		margin: 0;
		font: inherit;
		color: currentColor;
		width: 38px;
		height: 38px;
		// border: 0.15em solid currentColor;
		border-radius: 5px;
		border-radius: 30%;
		transform: translateY(-0.075em);
		display: grid;
		place-content: center;
	}
	input[type="checkbox"]::before {
		content: "";
		background-color: currentColor;
		width: 20px;
		height: 20px;
		transform: scale(0);
		transition: 120ms transform ease-in-out;
		box-shadow: inset 1em 1em var(--form-control-color);
		transform-origin: bottom left;
		background-color: orangered;
		clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
	}

	input[type="checkbox"]:checked {
		// background-color: orangered;
	}
	input[type="checkbox"]:checked::before {
		transform: scale(1);
		background-color: white;
	}

	ul {
		padding: 60px 0;
		list-style: none;
		text-align: left;
		font-size: 30px;
		@media screen and (max-width: 600px) {
			font-size: 20px;
		}
		input {
			margin-right: 10px;
		}
		label {
			font-family: "myoliver";
		}
		li {
			display: flex;
			align-items: center;
			padding: 4px 0;
			column-gap: 20px;
		}
	}
}
.question,
.reponse {
	padding: 50px;
	@media screen and (max-width: 600px) {
		padding: 40px 20px;
		// min-height: 90vh;
	}
}

.informations {
	display: flex;
	justify-content: space-between;
	padding-bottom: 30px;
}

h2.la_question {
	font-size: clamp(2em, 4vw, 50px);
	// color: orangered;
	max-width: 900px;
	margin: 0 auto;
	line-height: 1.1;
	@media screen and (max-width: 600px) {
		line-height: 0.95;
	}
}

.answers {
	display: flex;
	justify-content: center;
	column-gap: 140px;
	row-gap: 50px;
	padding-top: 40px;
	align-items: bottom;
	padding-bottom: 30px;
	@media screen and (max-width: 800px) {
		column-gap: 70px;
	}
	@media screen and (max-width: 600px) {
		align-items: center;
		flex-direction: column;
	}
	button.verify {
		color: white;
		margin: 0 auto;
		margin-top: 20px;
		transition: transform 0.4s ease-out;
		&:hover {
			color: white;
			background-color: blue !important;
			filter: none;
		}
	}
	button.answer {
		cursor: pointer;
		border: none;
		color: blue;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background-color: transparent;

		transition: transform 0.3s ease-out;
		&:hover {
			filter: invert(37%) sepia(100%) saturate(2978%) hue-rotate(346deg) brightness(95%) contrast(97%);
			transform: translateY(-5%) scale(1.1);
		}

		h4 {
			font-size: 40px;
			line-height: 1.1;
		}
		p {
			font-size: 20px;
		}
	}
}

.hide_reponse,
.hide_question {
	display: none;
}

.reponse {
	color: white;
	&__tag {
		font-size: 18px;
		font-family: "din";
		text-transform: uppercase;
		font-weight: 500;
		letter-spacing: 2px;
	}

	&__title {
		font-size: clamp(10px, 18vw, 80px);
	}
	&__title--checkbox {
		font-size: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
		input[type="checkbox"] {
			transform: scale(0.8);
		}
		label {
			font-size: 50px;
			font-size: 100px;
			font-size: clamp(10px, 16vw, 100px);
			font-family: "myoliver";
			padding-left: 40px;
		}
	}
	&__subtitle {
		font-size: 26px;
	}
	&__detail {
		padding: 20px 30px 40px 30px;
		margin: 0 auto;
		max-width: 720px;
		font-size: 22px;
	}
}

.wrong_answer {
	background-color: orangered;
	.cta--white {
		&:hover {
			background-color: blue;
		}
	}
}
.correct_answer {
	background-color: green;
	background-color: #19ce10;
	background-color: #13be0a;
	.cta--white {
		color: #13be0a;
		&:hover {
			color: white;
			background-color: blue;
		}
	}
}
