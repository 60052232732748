// $nightsky: rgb(7, 7, 22);
$nightsky: rgb(10, 10, 19);
$pinkred: rgb(255, 0, 0);
$pinkred: rgb(191, 0, 64);
// body {
// 	overflow: hidden;
// }

.consignes {
	color: red;
	position: fixed;
	bottom: 30px;
	right: 30px;
	max-width: 40px;
	cursor: pointer;
	z-index: 2;
}
.page_foxes {
	background-color: $nightsky;
	min-height: 100vh;

	#findEffect {
		mix-blend-mode: screen;
	}
	h1 {
		// font-size: 80px;
		// ##### Horizontal Ratio #####
		@media screen and (min-aspect-ratio: 16/19) {
			font-size: 13vh;
			pointer-events: none;
			// color: purple;
		}

		// ##### Vertical Ratio #####
		@media screen and (max-aspect-ratio: 16/9) {
			font-size: 8vw;
			// color: green;
		}
		font-weight: 100;
		position: absolute;
		z-index: 3;
		left: 50%;
		top: 6vh;
		transform: translateX(-50%);
		font-family: "neonblitz", sans-serif;
		color: #fff;
		// -webkit-backface-visibility: hidden;
		// -moz-backface-visibility: hidden;
		// -webkit-transform: translate3d(-50%, 0, 0);
		// -moz-transform: translate3d(-50%, 0, 0);

		text-shadow: 0 0 5px #fff, 0 0 10px $pinkred, 0 0 20px $pinkred, 0 0 40px $pinkred, 0 0 80px $pinkred,
			0 0 90px $pinkred, 0 0 100px $pinkred, 0 0 150px $pinkred;
	}

	.conteneur {
		width: fit-content;
		margin: 0 auto;
		position: relative;
		&:before,
		&:after {
			pointer-events: none;
			position: absolute;
			content: " ";
			z-index: 1;
			top: 0;
			height: 100%;
			width: 14vw;
			background-color: red;
		}
		&:before {
			left: 0;
			background: linear-gradient(to left, rgba(10, 10, 19, 0) 0%, rgba(10, 10, 19, 1) 100%);
		}
		&:after {
			right: 0;
			// opacity: 0.5;
			background: linear-gradient(to right, rgba(10, 10, 19, 0) 0%, rgba(10, 10, 19, 1) 100%);
		}
	}
	.vignetage {
		position: absolute;
		box-shadow: inset 0 0 1000px $nightsky;
		background: radial-gradient(transparent 50%, $nightsky), transparent;
		pointer-events: none;
		z-index: 1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
}
