@import "../styles/generics/colors";

.page_quizz_intro {
	text-align: center;
	color: white;
	Nav {
		position: absolute;
		top: 0;
	}

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h1 {
		font-family: "crunold";
		font-family: "myoliver";
		font-size: 200px;
		font-size: clamp(3em, 20vw, 200px);

		font-weight: 100;
		text-rendering: geometricPrecision;
		line-height: 1;
		padding-top: 80px;
		letter-spacing: -10px;
		letter-spacing: clamp(-0.05em, -1px, -10px);
		margin-bottom: 20px;
	}
	div.explanation {
		max-width: 520px;
		.catchphrase {
			font-family: "myoliver";
			font-size: 30px;
			// color: #1300bb;
			line-height: 1.16;
			padding: 20px 10px;
			@media screen and (max-width: 600px) {
				font-size: 22px;
			}
		}

		.expli {
			font-size: 20px;
			font-weight: 200;
			max-width: 450px;
			margin: 0 auto;
		}

		.oliver {
			text-decoration: underline;
			text-underline-offset: 4px;
			text-decoration-thickness: 1px;
			// text-decoration-color: orange;
		}
	}

	.cta_orangered {
		margin: 40px 0;
	}
}

.quizzDescription {
	margin-bottom: 20px;
}

.quizz_modal_container {
	background-color: white;
	margin: 0 auto;
	width: 100%;
	max-width: 1100px;

	color: $primaryBlue;
}

// .introScreen {
// 	background-color: orangered;
// 	width: 500px;
// 	min-height: 70vh;
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// 	align-items: center;

// 	.introTitle {
// 		font-size: 40px;
// 		font-weight: 100;
// 		max-width: 700px;
// 		margin: 0 auto;
// 		line-height: 1.1;
// 		color: white;
// 		padding-bottom: 40px;
// 	}
// 	p {
// 		color: white;
// 		font-size: 26px;
// 		text-align: center;
// 		max-width: 600px;
// 		margin: 0 auto;
// 		text-align: center;
// 	}
// 	.bouton_start {
// 		background-color: white;
// 		margin-top: 40px;
// 		border: none;
// 		border-radius: 40px;
// 		padding: 20px 50px;
// 		color: orangered;
// 		font-family: "din";
// 		text-transform: uppercase;
// 		letter-spacing: 2px;
// 	}
// }
