#ilefoxes {
	position: relative;


	img,
	video {
		// background-color: red;
		// max-width: 150%;
		// max-height:  ;p
		padding-bottom: 50px;
	}
	h2.island_title {
		margin-top: -30px;
		opacity: 0.999;
	}
}
