.page_quizz_play {
	Nav {
		position: absolute;
		top: 0;
	}
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	.quizz_modal_container {
		background-color: white;
		margin: 0 auto;
		width: 100%;
		max-width: 1100px;

		color: blue;
	}
}
