#modalgroulejeu {
	text-align: center;
	width: 90dvw;
	max-width: 1000px;
	padding: 80px 20px;

	h4 {
		font-family: "myoliver";
		font-size: 60px;
		text-rendering: geometricPrecision;
		font-weight: 100;
		margin: 0;
		margin-bottom: 20px;
		margin: 0 auto;
		max-width: 700px;
		text-align: center;
		line-height: 1;
	}
	.modal__description {
		padding: 30px 0;
		max-width: 650px;
		margin: 0 auto;
		line-height: 1.5;
	}

	p {
		padding: 4px 0;
		max-width: 500px;
		margin: 0 auto;
		@media screen and (max-width: 600px) {
			padding: 10px 0;
		}
	}
	.warning {
		background: orange;
		padding: 20px 20px 50px 20px;
		position: relative;
		max-width: 700px;
		margin: 0 auto;
		// max-width: ;
		line-height: 1.5;
		border-radius: 10px;
		h5 {
			font-size: 30px;
			margin: 0;
			margin-bottom: 20px;
		}
		.message {
			// display: flex;
			text-align: left;
		}
		#superRadar {
			width: 120px;
			margin-top: -50px;
			margin-bottom: -50px;
			// mix-blend-mode: overlay;
			// opacity: 0.5;
			// position: absolute;

			margin: 0 auto;
		}
	}

	.go-play-game {
		#arrowDouble {
			filter: invert(1);
			// transform: rotate(180deg);
			max-width: 200px;
			margin: 0 auto;
		}
		.credits {
			width: 100%;
			padding: 20px 0 0px 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			column-gap: 50px;
			.cred {
				max-width: 200px;
			}

			h5 {
				color: orange;
				font-size: 20px;
			}
			p {
				font-size: 16px;
			}
		}
	}
}
