#reponse4 {
	video {
		max-height: 200px;
	}
	.reponse__title {
		font-size: clamp(10px, 12vw, 80px);
	}

	.reponse__subtitle {
		display: flex;
		// background-color: yellow;
		justify-content: center;
		align-items: center;
		font-size: 30px;
		padding-top: 20px;
		span {
			font-family: "myoliver";
			// max-width: 50%;
			padding-left: 30px;
			line-height: 1;
			@media screen and (max-width: 600px) {
				padding-left: 30px;
				max-width: 240px;
			}
		}
		input {
			// margin-right: 30px;
		}
	}
	.reponse__detail {
		max-width: 740px;
	}
}
