.clouds_1,
.clouds_2 {
	position: fixed;
}
.clouds_1 {
	bottom: 0;
	left: 0;
	-webkit-transform: scaleX(-1) translate(51%, 22%);
	transform: scaleX(-1) translate(51%, 22%);
	animation: cloud_intro_apparition_1 2s ease-out;
}

.clouds_2 {
	-webkit-transform: scaleY(-1);
	transform: scaleY(-1) translate(50%, 20%);
	top: 0;
	right: 0;
	animation: cloud_intro_apparition_2 2s ease-out;
}

@media (width < 800px) {
	.clouds_1,
	.clouds_2 {
		height: 280px;
		width: auto;
	}
}
@media (width <600px) {
	.clouds_1,
	.clouds_2 {
		display: none;
	}
}
