@import "../styles/generics/colors";
.page_container_data_spectacle_swiper {
	mix-blend-mode: multiply;
}
.page_avantlespectacle {
	background-attachment: fixed;

	background-image: url("../assets/img/textures/fold_paper_4.webp");
	min-height: 100vh;
	background-color: white;

	position: relative;
	.bg-pol {
		opacity: 0.7;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		object-fit: cover;
	}
}
