nav {
	pointer-events: none;
	> * {
		pointer-events: auto;
	}
}
:root {
	--effect-x: 200px;
	--effect-y: 200px;
}
#findEffect {
	// mix-blend-mode: screen;
	// display: none;
	// width: 100px;
	height: 100px;
	width: auto;
	position: fixed;
	z-index: 100;
	top: var(--effect-y);
	left: var(--effect-x);
	transform: translate(-50%, -50%);
	pointer-events: none;
}
#foxesContainer {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */

	// background-color: red;
	aspect-ratio: 16/9;
	position: relative;
	@media (min-aspect-ratio: 16/9) {
		height: calc(100vh - 100px);
	}
	@media (max-aspect-ratio: 16/9) {
		width: 94vw;
	}
	#feteContainer {
		pointer-events: none;
		z-index: 0;
	}
	#lightContainer,
	#darkContainer,
	#hoverCharacterContainer,
	#feteContainer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
	}

	#darkContainer {
		background-image: url("../../../assets/img/foxes/foxe_scene_dark_2000x1125empty.webp");
	}
	&:is(#lightContainer) {
	}

	#lightContainer {
		background-image: url("../../../assets/img/foxes/foxe_scene_light_2000x1125empty.webp");
		--maskScale: 5.5%;
		// CLIP PATH
		cursor: none;
		transform: translateZ(0);
		will-change: transform;
		clip-path: circle(var(--maskScale) at 50% 50%);

		// clip-path: url(#mask);
		// transition: 0s ease-out;
		// transition-delay: 1ms;

		// ### Decomment this two for best transitions
		// transition: mask-path 0.4s ease-out;

		// MASK

		// transition: 0.2s ease-out;
		// transition-delay: 1ms;
		// -webkit-transition: 0ms clip-path linear;
		// // // mask-image: circle(7% at  var(--y));
		// mask-image: url("../../../assets/svg/circle.svg");
		// mask-repeat: no-repeat;
		// mask-size: var(--maskScale);
		// mask-position: var(--mask-x) var(--mask-y);
		// mask-transition: 0.2s ease-out;
		// transition: all 1s ease-out;
		// fin du re-test

		// mask-position: calc(var(--x) - 5%) calc(var(--y) - 10%);
		// -webkit-mask-size: 10%;
		// -webkit-mask-transition: none;
		// clip-path: circle(var(--maskScale) at 50% 50%);
		// -webkit-clip-path: circle(var(--maskScale) at 50% 50%);
		// clip-path: circle(7% at calc(var(--x) - 3%) calc(var(--y) - (7% / 5)));
		// @media screen and (min-width: 1400px) {
		// 	clip-path: circle(7% at calc(var(--x) - 3%) calc(var(--y) - (7% / 5)));
		// }

		svg {
			* {
				transition: 0.1s ease-out;
			}
		}
	}
	img.feteLights {
		position: absolute;
		object-fit: contain;
		transform: scale(1);
	}
	img.object,
	img.character,
	img.hover_character {
		cursor: pointer;
		position: absolute;
		transform-origin: center 100%;
		opacity: 100%;
		transition: transform 0.4s ease-out, opacity 0.8s ease-out;
		object-fit: contain;
		transform: scale(1);
	}
	img.hover_character {
		z-index: 1;
	}

	img.object_light {
		filter: saturate(90%);
		z-index: 100;
	}
	// img.object_light:hover {
	// 	transform: scale(1.05);
	// 	filter: saturate(130%);
	// }
	img.hover_character {
		opacity: 0;
		filter: brightness(0.5) contrast(1.2) saturate(0.5) hue-rotate(180deg);
		transition: transform 0.4s ease-out, opacity 0.8s ease-out;
		&:hover {
			opacity: 100%;
			filter: contrast(1.4) hue-rotate(-26deg);
			transform: scale(1.05);
		}
	}

	#herve_light,
	#herve_dark,
	#herve_hover {
		top: 41%;
		left: 19%;
		width: 7%;
		height: 42%;
	}

	#bambi_light,
	#bambi_dark,
	#bambi_hover {
		top: 43%;
		left: 47%;
		width: 7%;
		height: 29%;
	}
	#baptiste_light,
	#baptiste_dark,
	#baptiste_hover {
		top: 53.5%;
		right: 31.5%;
		width: 8.5%;
		height: 38%;
	}
	#lily_light,
	#lily_dark,
	#lily_hover {
		top: 32%;
		left: 33%;
		width: 11%;
		height: 20.5%;
		// background-color: rgba(255, 255, 0, 0.107);
	}
	#fox_light,
	#fox_dark,
	#fox_hover {
		bottom: 20.4%;
		right: 26.35%;
		width: 4.7%;
		height: 11%;
		transform-origin: center right;
	}
	#laliesse_light,
	#laliesse_dark,
	#laliesse_hover {
		top: 23%;
		left: 26.35%;
		width: 10.7%;
		height: 11%;
		transform-origin: center right;
		// background-color: red;
	}

	#journal_light,
	#journal_dark {
		bottom: 12%;
		left: 33%;
		width: 8%;
		height: 10.5%;
	}
	#micro_light,
	#micro_dark {
		bottom: 10%;
		left: 46%;
		width: 4%;
		height: 6%;
	}

	#drone_light,
	#drone_dark {
		bottom: 22%;
		left: 50.8%;
		width: 5%;
		height: 3.8%;
	}
	#casque_light,
	#casque_dark {
		bottom: 15.3%;
		left: 54.8%;
		width: 3.4%;
		height: 5%;
	}
	#gant_light,
	#gant_dark {
		transform-origin: top center;
		bottom: 40.3%;
		right: 19%;
		width: 3.4%;
		height: 10%;
	}
	#piano_light,
	#piano_dark {
		bottom: 27%;
		right: 18%;
		width: 6.5%;
		height: 6.6%;
		// background-color: red;
	}
	#cassette_light,
	#cassette_dark {
		bottom: 6%;
		left: 30%;
		width: 3.4%;
		height: 3.8%;
	}
	#affiche_light,
	#affiche_dark {
		bottom: 34%;
		left: 25%;
		width: 5.4%;
		height: 8.8%;
		// background-color: red;
	}

	#lumiereBafleDroit {
		top: 22%;
		right: 16%;
		width: 36%;
		height: 70%;
		transform-origin: 60% 50%;
		animation: lightFlicker 1.8s infinite ease-out alternate;
		// background-color: red;
	}
	#lumiereBafleGauche {
		top: 20%;
		left: 1%;
		width: 36%;
		height: 70%;
		animation: lightFlicker 1.8s infinite ease-out alternate;
		animation-delay: 1s;
	}
	#neonBar {
		top: 17.2%;
		left: 23.9%;
		width: 26%;
		height: 70%;
		animation: lightGlow 2.8s infinite linear alternate;
		animation-delay: 1s;
	}
	#neonPoleDance {
		bottom: 7.2%;
		left: 7.6%;
		width: 23.4%;
		height: 10%;
		animation: lightGlow 2.8s infinite linear alternate;
		animation-delay: 3s;
	}
	#neonVentilo {
		top: 45.7%;
		right: 35.9%;
		width: 11%;
		height: 20%;
		animation: lightGlow 2.8s infinite linear alternate;
		animation-delay: 3s;
		// background-color: rgba(255, 0, 0, 0.079);
	}
	.confettis {
		// background-color: red;
		height: 100%;
		width: 100%;
	}

	// .characters {
	// 	position: absolute;
	// 	width: 100%;
	// 	height: 100%;
	// 	// background-color: red;
	// 	clip-path: circle(10% at var(--x) var(--y));
	// 	transition: 0.5s ease-out;
	// }
	$characters: ("herve", "bambi", "baptiste", "lily", "fox", "laliesse");
	$objects: ("journal", "micro", "drone", "casque", "piano", "gant", "cassette", "affiche");

	@each $name in $characters {
		&:has(##{$name}_hover:hover) {
			#lightContainer {
				--maskScale: 11%;
				transition: 0.5s ease-out;
				// clip-path: circle(12% at var(--x) var(--y));
			}
			// background-color: red !important;
			##{$name}_light,
			##{$name}_dark {
				// opacity: 0;
				transform: scale(1.05);
			}
		}
	}
	@each $name in $objects {
		&:has(##{$name}_light:hover) {
			##{$name}_light,
			##{$name}_dark {
				// opacity: 0;
				transform: scale(1.05);
				filter: saturate(110%) hue-rotate(-26deg);
			}
		}
	}
}

// #hoverCharacterContainer {
// 	// &:has(img) {
// 	// 	background-color: red;
// 	// }
// }

@keyframes lightFlicker {
	0% {
		// opacity: 0;
		// filter: blur(1px);
		filter: drop-shadow(0px 0px 100px rgb(64, 58, 151));
		transform: perspective(100px);
	}

	100% {
		// opacity: 100%;
		transform: skew(3deg, 2deg);
		filter: drop-shadow(20px 20px 50px rgb(255, 38, 0)) hue-rotate(-10deg);
		// filter: saturate(150%) hue-rotate(-26deg) blur(0px);
		drop-shadow: 0 0 30px 50px #f30101;
	}
}
@keyframes lightGlow {
	0% {
		// opacity: 0;
		// filter: blur(1px);
		filter: saturate(90%);
	}

	100% {
		filter: saturate(130%) hue-rotate(-20deg) drop-shadow(00px 12px 50px rgb(255, 109, 109))
			drop-shadow(10px 12px 10px rgba(255, 109, 109, 0.5));
	}
}
