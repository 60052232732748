#question3 {
	.answers {
		width: fit-content;
		column-gap: 50px;
		margin: 0 auto;
		padding-right: 30px;
		@media screen and (max-width: 600px) {
			padding-right: 0px;
		}
	}
	.answer {
		margin: 0 auto;
		h4 {
			text-align: center;
			margin: 0 auto;
		}
		video {
			height: 140px;
			width: auto;
			max-width: 100%;
		}
		p {
			padding-top: 20px;
			text-aling: center;
			font-weight: 200;
			margin: 0 auto;
		}
	}
}
