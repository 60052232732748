#modal-foxes-choices {
	text-align: center;
	width: 900px;
	position: relative;
	p {
		max-width: 480px;
	}
	video {
		width: 25%;
		max-width: 220px;
		flex-shrink: 1;
		bottom: 0;
		right: 0;
		transform: rotate(-60deg);
		&:nth-child(1) {
			transform: scale(-1);
			transform: rotate(80deg);
		}
		@media screen and (max-width: 800px) {
			&:nth-child(1) {
				display: none;
			}
			position: static;
			left: 50%;
			width: 85%;
			// background-color: aqua;
			// rotate: 80deg;
			transform: rotate(80deg);
			bottom: 0;
			margin-bottom: -50px;
		}
		// background-color: red;
	}
	h4 {
		text-align: center;
		font-family: "myoliver";
		font-size: clamp(5em, 10vw, 90px);
		line-height: 0.9;
		padding-top: 30px;
		padding-bottom: 10px;
		font-weight: 100;
	}
	.content {
		display: flex;
		justify-content: center;
		padding-left: 30px;
		@media screen and (max-width: 600px) {
			flex-direction: column-reverse;
			padding: 0;
		}
		// align-items: center;
	}
	h5 {
		font-family: "myoliver";
		font-size: 40px;
		padding-bottom: 10px;
	}

	.text {
		line-height: 1.6;
		padding: 20px 0 0 0;
	}

	.arrow {
		// position: absolute;
		// top: -40px;
		// left: 50%;
		pointer-events: none;
		filter: invert(1);
		// transform: rotate(180deg) translateX(50%);
		width: 200px;
		margin: 0 auto;
		margin-bottom: -70px;
		position: relative;
		z-index: 10;
		opacity: 0.999;
	}
	.buttons {
		// background-color: yellow;
		display: flex;
		justify-content: center;

		column-gap: 20px;
		flex-wrap: nowrap;
		max-width: 100%;
		position: relative;
		width: fit-content;
		margin: 0 auto;
		margin-top: -10px;
		.button_container {
			margin: 0 auto;
			// background-color: red;
		}
		@media screen and (max-width: 800px) {
			flex-direction: column;
			margin-top: 20px;
			button {
				height: fit-content;
			}
		}
		button {
			margin: 20px;
			height: fit-content;
		}
	}
}
