#reponse7 {
	// background-color: yellow;

	.recette_commentary {
		padding: 40px 0;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		column-gap: 50px;
		row-gap: 60px;
		@media screen and (max-width: 900px) {
			grid-template-columns: 1fr 1fr;
		}
		@media screen and (max-width: 600px) {
			grid-template-columns: 1fr;
		}
		.recette_4 .proposition {
			position: relative;

			&:after {
				content: " ";
				padding: 20px 0;
				display: block;
				width: 100%;
				width: 100%;
				height: 100%;
				border: 1px solid white;
				position: absolute;
				border-radius: 50%;
				top: 50%;
				left: 50%;
				z-index: 10;
				transform: translate(-50%, -50%);
			}
		}
		p {
			font-family: "myoliver";
			text-align: center;
		}
		p.letter_choice {
			font-size: 40px;
		}
		ul {
			li {
				justify-content: center;

				width: 100%;
			}
			padding: 0;
			font-size: 18px;
		}
		h6 {
			padding-top: 20px;
			font-size: 56px;
		}
		p.commentaire {
			font-family: "din", sans-serif;
			font-weight: 300;
			text-transform: uppercase;
			font-size: 16px;
			max-width: 200px;
			margin: 0 auto;
			letter-spacing: 0.4px;
		}
	}
}
