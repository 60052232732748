#modalSoon {
	.foxes-content-template {
		h2,
		p {
			color: white;
			text-align: center;
		}
		h2 {
			font-size: 6rem;
			font-weight: 300;
		}
		h3 {
			color: orangered;
			font-size: 26px;
			margin-top: 14px;
			margin-bottom: 14px;
		}
		p {
			max-width: 450px;
			line-height: 1.4;
		}
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
