.page_quizz_resultat {
	text-align: center;
	color: white;
	.result_container {
		padding: 100px 0;
	}
	Nav {
		position: absolute;
		top: 0;
	}
	display: flex;
	justify-content: center;
	align-items: center;
	.resultat {
		color: white;
		font-weight: 100;
		// padding-bottom: 40px;
		padding: 0 20px 40px 20px;
		&__title {
			font-size: 40px;
			line-height: 0.8;
		}
		&__score {
			font-size: 80px;
			line-height: 1;
		}
		&__commentaire_title {
			font-size: 150px;
			font-size: clamp(10px, 14vw, 150px);
			max-width: 950px;
			line-height: 0.8;
			padding: 30px 20px;
			// background-color: yellow;
		}
		&__commentaire_description {
			font-size: 24px;
			max-width: 648px;
			margin: 0 auto;
		}
	}
	#resultat_cata {
		.resultat__commentaire_description {
			max-width: 440px;
		}
	}
	#resultat_pasmal {
		.resultat__commentaire_description {
			max-width: 580px;
		}
	}
}
