#reponse6 {
	video {
		max-height: 200px;
		max-width: 100%;
	}
	.reponse__title {
		// font-size: clamp();
	}
	.reponse__detail {
		max-width: 800px;
		line-height: 1.34;
	}
	input {
		transform: scale(0.6);
	}
	label {
		padding-left: 10px;
	}
}
