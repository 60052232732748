.foxtails-canvas-container {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;
	pointer-events: none;
	canvas {
		mix-blend-mode: multiply !important;
		background-color: transparent !important;
	}
}
