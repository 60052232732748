.template-internal-navigation {
	color: white;
	list-style: none;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	gap: 1rem;

	&__link {
	}
	&__link-active {
		color: orangered;
		font-weight: 400;
		text-decoration: underline;
		text-underline-offset: 10px;
	}
}

.character_picture {
	// background-color: #4fa4e4;
	position: relative;
	margin: 50px 0;
	width: 100%;
	z-index: 10;

	.character {
		transition: transform 0.5s ease-in-out;
		&:hover {
			transform: scale(1.2);
		}
		max-width: 230px;
		max-height: 300px;
		margin: 0 auto;
		display: block;
		height: fit-content;
		opacity: 0.9999;
		filter: contrast(150%) hue-rotate(-20deg) saturate(130%);
		// background-color: yellow;
	}
	.background_lampe {
		position: absolute;
		top: 10%;
		left: 50%;
		overflow: hidden;
		width: 240px;
		height: 240px;
		border-radius: 50%;
		opacity: 100%;

		transform: translate(-50%, -20%);
		z-index: -1;
		filter: saturate(100%) hue-rotate(-20deg) brightness(120%) blur(1px);
		img {
			width: 100%;
			height: 200%;
			object-fit: none;
			object-position: 0% 30%;
		}
	}
}
.foxes-titling {
	color: blue;
	color: white;
	text-align: center;
	padding-bottom: 10px;
	z-index: 8;
	position: relative;

	.title {
		font-size: 2.1rem;
		font-weight: 200;
		color: white;
		color: orangered;
	}
	.subtitle {
		font-size: 3.3rem;
		letter-spacing: -0.25rem;
		max-width: 600px;
		margin: 0 auto;
		font-weight: 200;
		padding: 20px 0;
		line-height: 1.1;
	}
}
.foxes-content-template {
	max-width: 1000px;
	margin: 0 auto;
	width: 100vw;
	min-height: 70vh;
	padding-top: 50px;
	padding-bottom: 50px;

	&__content {
		color: white;
		.title {
			font-size: 2rem;
			font-weight: bold;
			text-align: center;
			margin-bottom: 50px;
		}

		.description {
			color: blue;
			color: white;
			max-width: 400px;
			margin: 0 auto;
			text-align: center;
			line-height: 1.6;
		}
		&--contexte {
			// background-color: red;
			.video_container {
				// aspect-ratio: 16 / 9;
				max-width: 80%;
				margin: 40px auto 80px auto;
			}
			iframe {
				aspect-ratio: 16 / 10;
				width: 100%;
				height: fit-content;
			}
		}
		&--questions {
			.questions-list {
				// background-color: red;
				// color: blue;
				font-family: bold;
				text-align: center;
				text-align: center;
				// display: flex;
				// flex-wrap: wrap;
				// justify-content: center;

				li {
					font-size: 46px;
					list-style: none;
					max-width: 500px;
					line-height: 1;
					margin: 0 auto;
					padding: 100px 0;
					transition: transform 1s ease-out;
					&:last-child {
						padding-bottom: 200px;
					}
					&:hover {
						color: orangered;
						transform: scale(1.4);
					}
					&:nth-child(3n) {
						font-size: 60px;
						font-family: "Caveat", cursive;
					}
					&:nth-child(3n + 1) {
						font-family: "Homemade Apple", cursive;
						font-family: "Permanent Marker", cursive;
					}
					&:nth-child(3n + 2) {
						font-family: "myoliver", sans-serif;
					}

					&:nth-child(5n + 1) {
						rotate: 4deg;
					}
					&:nth-child(5n + 2) {
						rotate: -4deg;
					}
					&:nth-child(5n + 3) {
						rotate: 3deg;
						// padding-bottom: 20px;
					}
				}
			}
		}
		&--interview {
			iframe {
				aspect-ratio: 16 / 10;
				width: 100%;
				height: fit-content;
			}
			.video_container {
				margin: 50px auto;
				display: flex;
				justify-content: center;
				margin-bottom: 50px;
				max-width: 80%;
			}
			iframe {
				margin: 0 auto;
			}
		}
	}
}
