.modal {
	position: fixed;
	z-index: 9999;
	box-sizing: border-box;
	position: fixed;
	width: 100vw;
	height: 100%;
	overflow: scroll;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 20px;
	background-color: rgba(6, 2, 21, 0.8);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&.noflex {
		display: unset;
	}
	.modal__background {
		height: 100%;
		width: 100%;
		opacity: 80%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}
	.modal__content {
		margin: 0 auto;
		position: relative;
		height: fit-content;
		width: fit-content;
		max-width: 100%;
		// background-color: orange;
		// background-color: white;

		.modal__wrapper {
			padding: 30px 50px;
			width: 100%;
			max-width: 100%;
			// height: 100%;
			height: fit-content;
			position: relative;
			@media screen and (max-width: 600px) {
				padding: 70px 20px;
			}

			&--blue {
				color: white;
				background-color: #7caeda;
				background-color: #5492dd;
			}
			&--foxes-template {
				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgb(10, 10, 19);

					opacity: 100%;
					z-index: -1;
					filter: blur(30px) hue-rotate(-10deg) saturate(1.5);
				}
				&::after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;

					// background-image: url("../../assets/img/foxes/blurd3-22.jpg");
					z-index: -1;
					// ###### BG 22
					-webkit-backface-visibility: hidden;
					-moz-backface-visibility: hidden;
					-webkit-transform: translate3d(0, 0, 0);
					-moz-transform: translate3d(0, 0, 0);

					mix-blend-mode: screen;
					background-image: url("../../assets/img/foxes/Blurred--Abstract-Backgrounds_05.jpg");
					opacity: 70%;
					filter: blur(70px) saturate(1.3) brightness(1) contrast(0.95);
					background-size: 120% 100%;
					background-position: 20% center;

					// ###### BG 08
					// opacity: 100%;
					// background-image: url("../../assets/img/foxes/Blurred--Abstract-Backgrounds_08.jpg");
					// background-size: 180% 100%;
					// filter: blur(70px) hue-rotate(10deg) saturate(1.5) brightness(0.8);

					// ###### BG 13
					// background-image: url("../../assets/img/foxes/Blurred--Abstract-Backgrounds_13.jpg");
					// filter: blur(70px) saturate(1.6)  hue-rotate(0deg) brightness(0.9);
					// opacity: 60%;
					// background-size: 200% 110%;
				}
				// * {
				// 	color: #ffffff !important;
				// }
				// background-color: rgba(255, 243, 238);
				// background-color: rgb(255, 240, 234);
				// background-color: #0e0c2e;
				// background-image: url("../../assets/img/foxes/blurredabstractbackgrounds_13.jpg");
				// background-image: url("../../assets/img/foxes/Blurred--Abstract-Backgrounds_05.jpg");
				// background-image: url("../../assets/img/foxes/Blurred--Abstract-Backgrounds_13.jpg");
				// opacity: 80%;
				// background-image: url("../../assets/img/foxes/blurd3-02.jpg");
				background-size: 100% 100%;

				// * {
				// 	color: white !important;
				// }
				// border-radius: 100px;
				// box-shadow: 0 0 2px 2px rgb(255, 233, 224);
				// background: linear-gradient(
				// 	0deg,
				// 	rgb(255, 241, 234) 0%,
				// 	rgb(255, 240, 234) 100%
				// );

				padding: 0;
				padding-top: 50px;
				padding-bottom: 50px;
			}
		}
		.modal__content__close {
			cursor: pointer;
			position: absolute;
			z-index: 9997;
			right: 20px;
			top: 20px;
			width: 32px;
			height: 32px;
			opacity: 0.7;
			transition: transform 0.3s ease-out;
		}
		.modal__content__close:hover {
			filter: brightness(0.5) sepia(1) hue-rotate(0deg) saturate(10);
			opacity: 1;
			transform: scale(1.4);
		}
		.modal__content__close:before,
		.modal__content__close:after {
			position: absolute;
			left: 15px;
			content: " ";
			height: 33px;
			width: 2px;
			background-color: #ffffff;
		}
		.modal__content__close:before {
			transform: rotate(45deg);
		}
		.modal__content__close:after {
			transform: rotate(-45deg);
		}
	}
}

.modal__wrapper--foxes-template,
.modal__wrapper--affiche,
.modal__wrapper--cassette {
	.modal__content__close {
		opacity: 100% !important;
		z-index: 9999 !important;
	}
}

.modal__wrapper--foxes-template {
	.modal__content__close:before,
	.modal__content__close:after {
		background-color: orangered !important;
		opacity: 100% !important;
	}
}
