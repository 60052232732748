#modal-foxes-dommage {
	text-align: center;
	width: 900px;
	h4 {
		font-family: "myoliver";
		font-size: clamp(2em, 14vw, 80px);
	}
	h5 {
		font-family: "myoliver";
		font-size: 30px;	
		max-width: 500px;
		margin: 0 auto;
		line-height: 1.3;
		@media screen and (max-width: 600px) {
			line-height: 1.2;
			font-size: 26px;
		}
	}
	.redirect_user_content {
		padding-top: 40px;
		display: flex;
		justify-content: center;
		row-gap: 40px;
		column-gap: 80px;
		@media screen and (max-width: 600px) {
			flex-direction: column;
		}
		// background-color: red;
		p {
			max-width: 320px;
		}
	}

	.text {
		line-height: 1.6;
	}
	.cta_orangered {
		margin-top: 30px;
	}
}
