#iletadam {
	position: relative;
	&:hover {
		cursor: not-allowed;
	}

	> *:not(.warning-soon) {
		filter: grayscale(100%) opacity(50%);
	}
	.warning-soon {
		width: max-content;
		padding: 40px;
		line-height: 0.5;

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: orange;
		color: white;
		z-index: 10;
		border-radius: 50px;
		font-family: "myoliver";
		font-size: 20px;
		text-align: center;
		p {
			padding: 0;
		}
		.splash {
			position: absolute;
			top: 32%;
			left: 50%;
			width: 100%;
			height: 100%;
			filter: invert(1);
			// background-color: red;
			// height: 200px;
			// background-color: red;
			transform: translate(-50%, -50%) scale(1.7);
		}
	}
	&:hover {
		cursor: not-allowed;
	}
	.video_container {
		filter: grayscale(100%) opacity(80%) brightness(0.8);

		&:after {
			width: 30vw;
		}
	}
	img {
		max-height: 200px;
		width: auto;
		margin: auto;
		opacity: 0.7;
	}
	.text {
		opacity: 0.5;
	}
}
