@import "../../styles/generics/colors";
.coinCredit {
	position: fixed;
	max-width: 80px;
	bottom: 10px;
	right: 10px;
	z-index: 999;
	cursor: pointer;
	transition: 0.5s ease-out;
	&:hover {
		transform: scale(1.4);
	}
}
.navbar {
	position: absolute;
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
	z-index: 2;
	margin: 0 auto;
	padding: 40px 140px;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (height < 700px) {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	@media (width < 1000px) {
		padding-left: 20px;
		padding-right: 20px;
	}
	.logo_font_bonus {
		cursor: pointer;
		height: 40px;
		width: auto;
		transition: 0.2s ease-out;
		&:hover {
			transform: scale(1.3) rotate(-2deg);
		}
	}
}

.open_menu {
	border: none;
	aspect-ratio: 1/1;
	// width: 80px;
	height: auto;
	padding: 16px;
	background-color: blue;
	border-radius: 50%;
	transition: transform 0.5s ease-out;
	cursor: pointer;
	svg {
		max-width: 24px;
	}
	&:hover {
		background-color: orange;
		transform: scale(1.1);
	}
}
.close_menu {
	cursor: pointer;
	svg {
	}
	&:hover {
		filter: invert(27%) sepia(51%) saturate(2978%) hue-rotate(346deg) brightness(95%) contrast(97%);
	}
}
.fixed_menu {
	position: fixed;

	background: #0087c6;
	right: 0;
	top: 0;
	height: 100vh;
	z-index: 200;
	width: 400px;
	transition: 0.4s ease-out;
	transform: translateX(100%);
	&.is_open {
		transform: translateX(0);
	}
	.close_menu {
		position: absolute;
		right: 10px;
		top: 10px;
		padding: 20px;
	}
	ul {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 16px;
		a {
			color: white;
			text-decoration: none;
		}
	}
	.dirigeable {
		max-width: 200px;
		margin-bottom: 50px;
		animation: floating_dirigeable 1.5s infinite alternate ease-in-out;
		animation-fill-mode: backwards;
		transition: 0.5s ease-out;
		&:hover {
			scale: 1.2;
		}
	}
	li {
		list-style: none;
		padding: 0 10px;
		font-size: 18px;
		font-family: "din";
		font-weight: 200;
		text-rendering: optimizeLegibility;
		&.link a {
			&::after {
				content: "";
				display: block;
				background: orange;
				width: 0%;
				height: 2px;
				transition: all 0.2s ease-out;
			}
			&:hover {
				color: orange;

				&::after {
					width: 100%;
				}
			}
		}
		&.backrenards {
			// background-color: red;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 5vh;
			.back_icon {
				transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
				margin-right: 20px;
			}
			&:hover {
				.back_icon {
					transform: scale(1.3) rotate(180deg);
					filter: brightness(0.6) sepia(3) saturate(10);
				}
			}
		}
	}
	.not_available {
		color: white;
		opacity: 0.3;
	}
}
