.toolbar {
	// position: absolute;
	// bottom: 0;
	// left: 50%;
	// transform: translateX(-50%) translateY(100%);
	width: 100%;

	.music-player {
		position: fixed;
		z-index: 99;
	}

	&__container {
		background-color: rgb(1, 19, 42);
		padding: 20px 0;
		display: flex;
		justify-content: center;
		align-items: center;
		// border-radius: 90px;
		// max-width: 500px;
		margin: 0 auto;
		opacity: 0.9;
		transition: 0.5s ease-out;
		color: white;
		&:hover {
			opacity: 1;
		}
	}
	&__objects {
		padding: 10px 0;
		display: flex;
		justify-content: center;
		gap: 30px;
		min-height: 40px;
		margin-right: 50px;
	}
	.toggle-player-button {
		border: none;
		cursor: pointer;
		background-color: orangered;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		transition: transform 0.5s ease-out;
		img {
			max-width: 50%;
			max-height: 50%;
		}
		&:hover {
			transform: scale(1.1);
		}
	}

	.toggle-player-button[found="false"] {
		filter: grayscale(100%) opacity(50%);
		pointer-events: none;
	}
	.toggle-player-button[found="true"] {
		display: unset;
	}

	.show-player {
		display: unset;
	}
	.hide-player {
		display: none;
	}
	.found_object {
		position: relative;
		// min-width: 14px;
		// min-height: 14px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: auto;
		img {
			max-height: 24px;
		}

		&::after {
			background-color: rgba(200, 199, 198, 0.3);
			width: 16px;
			height: 16px;
			content: "";
			display: inline-block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			border-radius: 50%;
			filter: blur(2px);
		}
	}
	.found_object[found="false"] {
		// filter: grayscale(100%) opacity(50%);
		img {
			display: none;
		}
	}
	.found_object[found="true"] {
		filter: hue-rotate(-10deg);
		&::after {
			opacity: 0;
		}
	}
}
