$breakpoints: (
  'mobile':  480px,
  'small':  767px,
  'medium': 992px,
  'large':  1200px,
  'wide':  1400px
) !default;



/*-------------------------------- */
// ##### MIN BREAKPOINTS  ##### 
/*-------------------------------- */

@mixin max-w($breakpoint) {
  
  // Si la clé existe
  @if map-has-key($breakpoints, $breakpoint) {
    // Intégrer le média querry suivant
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
 
  @else {
    @warn "Désolé mais il semble y avoir un problème avec la valeur `#{$breakpoint}`. "
        + "Les breakpoints disponibles sont: #{map-keys($breakpoints)}.";
  }
}

/*-------------------------------- */
// ##### MAX BREAKPOINTS  ##### 
/*-------------------------------- */ 


@mixin min-w($breakpoint) {
  
  // Si la clé existe
  @if map-has-key($breakpoints, $breakpoint) {
    // Intégrer le média querry suivant
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
 
  @else {
    @warn "Désolé mais il semble y avoir un problème avec la valeur `#{$breakpoint}`. "
        + "Les breakpoints disponibles sont: #{map-keys($breakpoints)}.";
  }
}


