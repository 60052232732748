#question1 {
	img {
		max-height: 100px;
	}
	video {
		max-height: 150px;
		transform: translateY(-20%);
	}

	img,
	video {
		margin-bottom: -30px;
		@media screen and (max-width: 600px) {
			margin-bottom: 0px;
		}
	}
}
