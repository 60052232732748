#modalcourrier {
	max-width: 1200px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	.text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		h4 {
			font-family: "myoliver";
			font-size: 60px;
			text-rendering: geometricPrecision;
			font-weight: 100;
			margin: 0;
			margin-bottom: 20px;
			text-align: center;
			line-height: 0.8;

			.heartPicture {
				height: 70px;
				margin-left: 20px;
			}
		}
		p {
			font-size: 20px;
			max-width: 420px;
			line-height: 1.7;
			text-align: center;
		}
		.cta_orangered {
			padding: 16px 40px;
			margin: 0;
			margin-top: 40px;
		}
	}
	// .modalcover {
	// 	width: 250px;
	// 	height: 250px;
	// 	background-color: white;
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	color: red;
	// }
	video {
		// background-color: red;
		width: 300px;
		height: 300px;
		margin-bottom: -50px;
	}
	.arrow {
		filter: invert(1);
		width: 100px;
		// position: absolute;
		left: 0;
		bottom: 0;
		transform: rotate(26deg);
		&:nth-child(1) {
			transform: scale(-1, 1) rotate(26deg);
		}
		@media screen and (max-width: 400px) {
			display: none;
		}
	}
	.goget {
		display: flex;
	}
}
