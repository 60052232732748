#question4 {
	h2 {
		// color: orangered;
		// font-size: 60px;
		max-width: 900px;
		line-height: 1;
	}
	h3 {
		color: orangered;
		font-weight: 400;
		font-size: 24px;
		margin-bottom: 20px;
		text-transform: uppercase;
		line-height: 1;
	}
	.answers {
		display: block;
		width: fit-content;
		margin: 0 auto;
		padding: 0;
	}

	// button {
	// 	background-color: orangered;
	// 	color: white;
	// 	margin: 0 auto;
	// 	margin-top: 20px;
	// 	&:hover {
	// 		color: white;
	// 		background-color: blue !important;
	// 		filter: none;
	// 	}
	// }
}
