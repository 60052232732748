#question6 {
	// background-color: red;
	h3 {
		color: orangered;
		max-width: 800px;
		margin: 0 auto;
		font-size: 24px;
	}
	h2 {
		font-size: clamp(2em, 4vw, 60px);
		max-width: 900px;
		line-height: 0.9;
		padding-top: 20px;
	}
	video {
		max-height: 150px;
		max-width: 100%;
		margin: 40px 0 50px 0;
	}
	.answers {
		padding-top: 0px;
		display: block;
		width: fit-content;
		margin: 0 auto;
		ul {
			padding-top: 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			column-gap: 30px;
			row-gap: 20px;
			@media screen and (max-width: 600px) {
				row-gap: 5px;
				flex-direction: column;
				li{
					font-size: 28px;
				}
			}
		}
	}
}
