@import "../../styles/generics/_breakpoints.scss";

#modal-foxes-chouette {
	text-align: center;

	p {
		max-width: 580px;
		margin: 20px auto;
	}

	h4 {
		font-size: 80px;
		@include max-w("small") {
			font-size: 55px;
		}
	}

	h5 {
		font-size: 30px;
		@include max-w("small") {
			font-size: 25px;
		}
	}
	p {
		line-height: 1.6;
	}
}
