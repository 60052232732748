@import "../../styles/generics/ctas";
@import "../../styles/generics/_breakpoints.scss";

.credit_title {
	font-size: clamp(3em, 10vw, 40px) !important;
}
#credit_title_font {
	max-height: 150px;
	max-width: 100%;
	display: block;
	margin: 0 auto;
	margin-bottom: 50px;
}
.credits_container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	margin: 0 auto;
	@include max-w("medium") {
		grid-template-columns: 1fr;
	}
}

.credit-col {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-bottom: 30px;
	&--direction {
		.duo {
			display: flex;
			gap: 30px;
		}
	}
	.fonction {
		color: orange;
		font-size: 20px;
		padding-bottom: 10px;
	}
	img {
		margin-bottom: 20px;
		max-width: 150px;
		max-height: 56px;
		opacity: 90%;
	}
	p {
		line-height: 1.3 !important;
		opacity: 0.8;
	}
}
// #modalcredits {
// 	width: 900px;

// 	video {
// 		max-height: 200px;
// 		// background-color: yellow;
// 		// position: absolute;
// 	}
// 	.modalcover {
// 		position: absolute;
// 		width: 250px;
// 		height: 250px;
// 		bottom: 0;
// 		left: 0;
// 		background-color: white;
// 		display: flex;
// 		justify-content: center;
// 		align-items: center;
// 		color: red;
// 	}
// 	h4 {
// 		font-weight: 100;
// 		text-rendering: geometricPrecision;
// 		margin: 0;
// 		margin-bottom: 20px;
// 		font-size: 70px;
// 		font-size: clamp(3em, 10vw, 70px);

// 		line-height: 1;
// 		margin: 0 auto;
// 		width: fit-content;
// 		text-align: center;
// 		padding-bottom: 40px;

// 		.smaller {
// 			font-size: 40px;
// 		}
// 	}
// 	p {
// 		line-height: 1.8;
// 	}

// 	.part1 {
// 		// background-color: red;
// 		margin: 0 auto;
// 		position: relative;
// 		max-width: 100%;
// 		width: 800px;
// 		display: flex;
// 		flex-wrap: wrap;
// 		justify-content: center;
// 		align-items: start;

// 		.text {
// 			max-width: 450px;
// 			margin-right: 40px;
// 			// padding-left: 100px;
// 			@media screen and (max-width: 600px) {
// 				text-align: center;
// 				margin-right: 0;
// 			}
// 		}
// 		video {
// 			pointer-events: none;
// 			position: absolute;
// 			// background: red;
// 			max-width: 150px;
// 			bottom: 0;
// 			right: 0;
// 			transform: translate(-90%, 20%) rotate(-50deg);
// 		}

// 		.ball_cta {
// 			display: block;
// 			bottom: 0;
// 			right: 0;
// 			width: fit-content;
// 			height: auto;
// 			background-color: #fc831b;
// 			background-color: #ff7905;
// 			aspect-ratio: 1/1;
// 			border-radius: 100%;
// 			display: flex;
// 			justify-content: center;
// 			align-items: center;
// 			transition: 0.4s ease-out;
// 			transform-origin: center center;
// 			@media screen and (max-width: 600px) {
// 				margin-top: 40px;
// 			}
// 			p {
// 				padding: 50px;
// 				line-height: 1;
// 			}
// 			&:hover {
// 				transform: scale(1.4);
// 				rotate: -4deg;
// 			}
// 		}
// 	}

// 	.part2 {
// 		// background-color: red;
// 		width: fit-content;
// 		margin: 0 auto;
// 		margin-top: 70px;
// 		display: flex;
// 		align-items: center;
// 		column-gap: 20px;
// 		// background-color: red;
// 		@media screen and (max-width: 600px) {
// 			flex-direction: column-reverse;
// 		}
// 		.texte {
// 			text-align: center;
// 		}
// 		p {
// 			line-height: 1.2;
// 			margin-bottom: 10px;
// 			max-width: 360px;
// 		}
// 		video {
// 			max-height: 150px;
// 			@media screen and (max-width: 600px) {
// 				margin-top: 50px;
// 			}
// 		}
// 		.cta--blue {
// 			margin-bottom: 0;
// 		}
// 	}
// }
