/* ########### FONT BONUS ########### */
@keyframes font_bonus_apparition {
	from {
		opacity: 0;
		transform: translateY(100vh);
	}
	to {
		opacity: 1;
		transform: translateY(0%);
	}
}

/* ########### BOUTON ########### */
@keyframes button_apparition {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes dirigeable_apparition {
	from {
		transform: translateY(100vh);
		filter: blur(1.4px);
	}
	to {
		transform: translateY(0%);
		filter: blur(0px);
	}
}
/* ########### DIRIGEABLE ########### */
@keyframes floating_islands {
	0% {
		margin-top: 0;
	}
	50% {
		margin-top: -8px;
	}
	100% {
		margin-top: 0;
	}
}

/* ########### CLOUDS ########### */
@keyframes cloud_intro_apparition_1 {
	from {
		opacity: 0;
		-webkit-transform: scaleX(-1) translate(-40%, 20%);
		transform: scaleX(-1) translate(100%, 20%);
	}
	to {
		opacity: 1;
		-webkit-transform: scaleX(-1) translate(51%, 22%);
		transform: scaleX(-1) translate(51%, 22%);
	}
}
@keyframes cloud_intro_apparition_2 {
	0% {
		opacity: 0;
		-webkit-transform: scaleY(-1);
		transform: scaleY(-1) translate(100%, 20%);
	}
	100% {
		opacity: 1;
		-webkit-transform: scaleY(-1);
		transform: scaleY(-1) translate(50%, 20%);
	}
}


/* ########### DIRIGEABLE FLOTANT ########### */

@keyframes floating_dirigeable {
	from {
		transform: translateY(-5px);
	}
	to {
		transform: translateY(5px);
	}
}

/* @keyframes floating_dirigeable {
	0% {
		margin-top: 0;
	}
	50% {
		margin-top: -8px;
	}
	100% {
		margin-top: 0;
	}
} */

/* ########### DIRIGEABLE FLOTANT ########### */

@keyframes floating_islands {
	from {
		transform: translateY(-4px);
	}
	to {
		transform: translateY(4px);
	}
}

/* ########### CARRE DE TEST ########### */
.animate {
	background-color: red;
	width: 50px;
	height: 50px;
	animation-duration: 2s;
	animation-name: animate-fade;
	animation-delay: 2s;
	animation-fill-mode: backwards;
}

@keyframes animate-fade {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
