@import url("https://fonts.googleapis.com/css2?family=Caveat+Brush&family=Caveat:wght@600&family=Homemade+Apple&family=Permanent+Marker&display=swap");
#question2 {
	.answers {
		padding-top: 40px;
		column-gap: 30px;
		row-gap: 10px;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		// background-color: aqua;
		max-width: 600px;
		margin: 0 auto;
		.answer {
			width: fit-content;
			font-size: 56px;
			line-height: 1;
			color: blue;
			@media screen and (max-width: 600px) {
				font-size: 36px;
				line-height: 0.8;
			}
			&:nth-child(3n) {
				font-size: 60px;
				font-family: "Caveat", cursive;
			}
			&:nth-child(3n + 1) {
				font-family: "Homemade Apple", cursive;
				font-family: "Permanent Marker", cursive;
			}
			&:nth-child(3n + 2) {
				font-family: "myoliver", sans-serif;
			}

			&:nth-child(5n + 1) {
				rotate: 4deg;
			}
			&:nth-child(5n + 2) {
				rotate: -4deg;
			}
			&:nth-child(5n + 3) {
				rotate: -14deg;
				// padding-bottom: 20px;
			}
		}
	}
}
