#reponse2 {
	.reponse__detail {
		max-width: 600px;
		&:nth-child(2n){
			padding-bottom: 14px;

		}
		&:nth-child(2n+1){
			padding-top: 10px;
			font-size: 18px;
			// background-color: rebeccapurple;
		}
	}


}
